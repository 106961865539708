window.$ = window.jQuery = require('./jquery3.6.1.min.js');

require('./webflow');

$(document).ready(function () {
	document.getElementById('video-placeholder').addEventListener('click', function () {
		this.innerHTML = '<iframe src="https://www.youtube.com/embed/zcRU0FzmipA?rel=0&autoplay=1&origin=https://linkdeli.com" width="560" height="315" frameborder="0" allowfullscreen="false">';
		this.className = 'active';
	});


	//window.cookieTool = require('./CookieToolWrapper');
	//
	//var cookieTool2 = window.cookieTool.cookie_tool();
	//
	//var configuration = {
	//	"scopes": [
	//		{
	//			"scope": "functional",
	//			"required": 1,
	//			"title": "Functional cookies",
	//			"description": "These are the cookies we set purely for functionality in our website and not for any tracking purposes. For example if we do not set a cookie that stores these settings, we would not be able to not constantly prompt you with this screen."
	//		},
	//		{
	//			"scope": "analytical",
	//			"required": 0,
	//			"title": "Analytical cookies",
	//			"description": "With these cookies, you allow our third analytics and track services to function properly. We use this information to gain insight on users of the website and improve our services "
	//		}],
	//	"messages": {
	//		"required_help": "These cookies can not be unset during your visit to this website, because this would result in degraded performance.",
	//		"settings_button": "Cookie Preferences",
	//		"cookiebar_title": "Cookies Preferences",
	//		"cookiebar_button": "Save Preferences",
	//		//"cookiebar_description": "We respect your privacy and let you set which cookies we will sent to your browser. If you wish to change these settings later on, you can do so through the link in the website footer."
	//		"cookiebar_description": ""
	//	}
	//};
	//
	//window.cookieTool.gdpr_compliance(configuration, cookieTool2).init();
});
